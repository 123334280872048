import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Seo from "../../components/SeoComponent";
import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import SectionImage from "../../components/ui/SectionImage";
import ListText from "../../components/ui/ListText";
import SectionImagePlayer from "../../components/ui/SectionImagePlayer";
import SectionSubMenu from "../../components/ui/SectionSubMenu";
import ReadMore from "../../components/ReadMore";
import Footer from "../../components/Footer";

import { EXPERTISES_SUBMENU } from "../../constants/constants";

const Aerospace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("page-expertise.aerospace.metadata.name")}
        description={t("page-expertise.aerospace.metadata.description")}
      />
      <Header />
      <TitlePage category={t("page-expertise.aerospace.category")} title={t("page-expertise.aerospace.title")} />
      <SectionImage imageHD="plane-motor.jpg" imageMD="plane-motor-md.jpg" imageSD="plane-motor-sd.jpg" alt="plane" />
      <SectionTextPage>
        <p className="section-title">{t("page-expertise.aerospace.s1-1")}</p>
        <p className="section-subtitle">{t("page-expertise.aerospace.s1-2")}</p>
        <ListText text={t("page-expertise.aerospace.s1-l1")} />
        <ListText text={t("page-expertise.aerospace.s1-l2")} />
        <p>{t("page-expertise.aerospace.s1-p1")}</p>
        <p>{t("page-expertise.aerospace.s1-p2")}</p>
        <p>
          {t("page-expertise.aerospace.s1-p3")}
          <br />
          {t("page-expertise.aerospace.s1-p4")}
        </p>
        <p>{t("page-expertise.aerospace.s1-p5")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-title">{t("page-expertise.aerospace.s2-1")}</p>
        <p>{t("page-expertise.aerospace.s2-p1")}</p>
        <p>{t("page-expertise.aerospace.s2-p2")}</p>
        <p>{t("page-expertise.aerospace.s2-p3")}</p>
        <p className="section-subtitle">{t("page-expertise.aerospace.s2-2")}</p>
        <ListText text={t("page-expertise.aerospace.s2-l1")} />
        <ListText text={t("page-expertise.aerospace.s2-l2")} />
        <ListText text={t("page-expertise.aerospace.s2-l3")} />
        <ListText text={t("page-expertise.aerospace.s2-l4")} />
        <ListText text={t("page-expertise.aerospace.s2-l5")} />
        <ListText text={t("page-expertise.aerospace.s2-l6")} />
      </SectionTextPage>

      <SectionTextPage>
        <p className="section-title">{t("page-expertise.aerospace.s3-1")}</p>
        <p className="section-subtitle">{t("page-expertise.aerospace.s3-2")}</p>
        <ListText text={t("page-expertise.aerospace.s3-l1")} />
        <p className="section-subtitle">{t("page-expertise.aerospace.s3-3")}</p>
        <ListText text={t("page-expertise.aerospace.s3-l2")} />
        <ListText text={t("page-expertise.aerospace.s3-l3")} />
        <ListText text={t("page-expertise.aerospace.s3-l4")} />
        <ListText text={t("page-expertise.aerospace.s3-l5")} />
        <ListText text={t("page-expertise.aerospace.s3-l6")} />
      </SectionTextPage>

      <SectionImagePlayer
        imageHD="plane-motor-2.jpg"
        imageMD="plane-motor-2-md.jpg"
        imageSD="plane-motor-2-sd.jpg"
        href={t("page-expertise.aerospace.video-url")}
        target="_blank"
      />

      <SectionSubMenu menus={EXPERTISES_SUBMENU} title={t("page-expertise.other-menu-title")} codeExclude="air" />

      <ReadMore title={t("page-expertise.aerospace.readmore")} news={[]} />

      <Footer />
    </>
  );
};

export default Aerospace;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
