import React from "react";

/**
 * Display text in list format for Ziegler Special Request
 * @param string text - Text displayed in the list
 * @returns
 */
const ListText = props => {
  return (
    <div className="cf list-text">
      <div className="fl w-10" style={{ maxWidth: "40px", paddingTop: "0px" }}>
        <div className="section-list"></div>
      </div>
      <div className="fl w-90 pl3">{props.text}</div>
    </div>
  );
};

export default ListText;
